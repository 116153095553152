import React, { useEffect, useState } from "react";
import { ISalonSearchLeftDrawerBar } from "../../../../interfaces/guests";
import getDirection from "../../../../utils/getLanguageDirection";
import "./style.css";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import SelectedSalonContainer from "../SalonSearchSidebar/SelectedSalonContainer";
import RegisterPopup from "./RegisterPopup";
import * as guestsSelectors from "../../../../store/selectors/guestsSelectors";
import { useTranslation } from "react-i18next";
import successIcon from "../../../../assets/icons/success-logo.png";
import xIcon from '../../../../assets/icons/x.svg';
import { SalonDetails } from "./SalonSerachLeftDrawerStyles";
import {useIsMobile} from "../../../../utils/usIsMobile";
import * as authSelector from "../../../../store/selectors/authSelectors";
import WitnessDetailsAndComments from "./WitnessDetailsAndComments/WitnessDetailsAndComments";
import { cleanDisplayAddress } from "../../../Hosts/utils";

declare var google;

const SalonSearchLeftDrawerBar: React.FC<
  ISalonSearchLeftDrawerBar
> = React.memo(
  ({ selectedSalonDetails = {}, guestLatLng, modalIsOpen, setIsOpen, isNoAvailableSpaces }) => {
    const [distance, setDistance] = useState<string>("");
    const langDirection = getDirection();
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const host = useSelector(authSelector.hostSelector);
    const isSalonWitnessAndComments = selectedSalonDetails?.witness_full_name || selectedSalonDetails?.comments_for_guests
    
    const accessibilityOptionsToStr = {
      "Elevator": t("formFields.elevator"),
      "Wheelchair accessibility": t("formFields.wheelChair"),
    };

    const salonDetailsMap = (selectedSalonDetails) => {
      const cleanAddress = cleanDisplayAddress(selectedSalonDetails.address);
      return cleanAddress
        ? `${t("host.hostProfile.address")} ${cleanAddress}${
            selectedSalonDetails.floor && selectedSalonDetails.floor !== 9999
              ? `, ${t("host.hostProfile.floor")} ${selectedSalonDetails.floor}`
              : ""
          }${
            selectedSalonDetails.apartment_number &&
            selectedSalonDetails.apartment_number !== 9999
              ? ` ${t("host.hostProfile.apartment")} ${selectedSalonDetails.apartment_number}`
              : ""
          }${
            selectedSalonDetails.house_number
              ? ` ${t("formFields.floor")} ${selectedSalonDetails.house_number}`
              : ""
          }`
        : selectedSalonDetails.distance
        ? langDirection === "rtl"
          ? `${selectedSalonDetails.distance} ק״מ ממך`
          : `${selectedSalonDetails.distance} km from you`
        : "";
    };

    const lablesArr = [
      t('guests.salonSearchMap.dateAndHour'),
      t('guests.address'),
      t('formFields.accessibility'),
      t('formFields.language'),
      t('guests.hostSalonNote')
    ];

    const lablesArrDigitalSalon = [
      t('host.hostProfile.salonType'),
      t('guests.salonSearchMap.dateAndHour'),
      t('formFields.language'),
      t('guests.hostSalonNote')
    ];

    const splitDateFormated = selectedSalonDetails.event_time?.split("T")[0];
    const dateFormated = splitDateFormated?.split("-")?.reverse().join('.');
    const hours = selectedSalonDetails.event_time?.split("T")[1].slice(0, 5);
    const salonAccessability = selectedSalonDetails.accessibility;
    const accessabilityMessages = salonAccessability?.length ? salonAccessability.map((accessibility) => `${accessibilityOptionsToStr[accessibility]}`) : [];
    if (selectedSalonDetails.floor === 0) {
      accessabilityMessages.push(t("assignHostWitnessModal.floor0"))
    }
    const accessabilityMessage =
      accessabilityMessages?.length
        ? accessabilityMessages.join(', ')
        : t("formFields.none");

    const valuesArr: any = [
      <p>
        <span>{dateFormated} , </span>
        <span> {hours}</span>
      </p>,
      <p>
        <span>{salonDetailsMap(selectedSalonDetails)}</span>
      </p>,
      <span>{accessabilityMessage}</span>,
      selectedSalonDetails.language_name,
      isSalonWitnessAndComments ? (      
        <WitnessDetailsAndComments {...selectedSalonDetails} />
      ) : '',
    ];

    const valuesArrDigitalSalon: any = [
      <p>
        <span> {`${t('host.hostProfile.digital')}`} </span>
      </p>,
      <p>
        <span>{dateFormated} , </span>
        <span> {hours}</span>
      </p>,
      selectedSalonDetails.language_name,
      selectedSalonDetails.comments_for_guests,
    ];

    const calculateAvailableSpots =
      selectedSalonDetails.max_guests -
      selectedSalonDetails.invites_pending_count -
      selectedSalonDetails.invites_confirmed_count;

    useEffect(() => {
      if (typeof google === "undefined" || !google.maps) return;

      if (selectedSalonDetails.lat && selectedSalonDetails.lng) {
        const salonLatLng = new google.maps.LatLng(
          selectedSalonDetails.lat,
          selectedSalonDetails.lng
        );

        const service = new google.maps.DistanceMatrixService();
        const parseDataCb = (response: any, status: string) => {
          let innerStatus: string;
          if (response) innerStatus = response.rows[0].elements[0].status;
          if (status === "OK" && innerStatus === "OK") {
            const distance = response.rows[0].elements[0].distance.text;

            const translatedString =
              langDirection === "rtl"
                ? distance.replace("km", 'ק"מ')
                : distance.replace('ק"מ', "km");
            setDistance(translatedString);
          } else {
            setDistance("");
          }
        };
        service.getDistanceMatrix(
          {
            origins: [guestLatLng],
            destinations: [salonLatLng],
            travelMode: "DRIVING"
          },
          parseDataCb
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guestLatLng, langDirection]);

    const [openSuccessPopup, setOpenSuccessPopup] = useState<boolean>(false);

    const customStyles = {
      content: {
        maxWidth: isMobile ? "100%" : "640px",
        width: isMobile ? "100%" : "50%",
        position: "absolute",
        height: "100vh",
        left: langDirection === "ltr" ? "unset" : "0",
        right: langDirection === "rtl" ? "unset" : "0",
        top: "0",
        background: "white",
        zIndex: "100",
        textAlign: "start",
        border: "none",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        direction: langDirection
      }
    };
    const guestId = useSelector(guestsSelectors.guestIdSelector);


    const [inputVal, setInput] = useState(1);
    return (
      <>
        {modalIsOpen && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => { }}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div className="close-box">
              <button onClick={() => {
                const pathname = window.location.pathname;
                const newPathName =pathname.slice(0,pathname.lastIndexOf('/'));
                if(newPathName.includes('mapview'))
                  window.location.pathname = newPathName;
                setIsOpen(false)
                }} className="close-btn">
                <img className="x-icon" src={xIcon} alt="x-icon"></img>
              </button>
            </div>
            <div className="salon-name-header">
              <h1 className="salon-details-header"><span> {t("host.salonOf")} {selectedSalonDetails.salon_name || selectedSalonDetails.host_name}</span>  </h1>
            </div>
            <hr className="drawer-hr" />
            <div className="inputs-container">
              {
                selectedSalonDetails.is_digital ?
                  lablesArrDigitalSalon.map((label, index) => {
                    return (
                      <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
                        <label htmlFor="label" className="label-name">
                          {label}
                        </label>
                        {(valuesArrDigitalSalon[index] !== "") ? (<SalonDetails direction={langDirection}>{valuesArrDigitalSalon[index]}</SalonDetails>) : (<SalonDetails direction={langDirection}>{t("host.none")}</SalonDetails>)}
                      </div>
                    );
                  })
                  : lablesArr.map((label, index) => {
                    return (
                      <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
                        <label htmlFor="label" className="label-name">
                          {label}
                        </label>
                        {(valuesArr[index] !== "") ? (<SalonDetails direction={langDirection}>{valuesArr[index]}</SalonDetails>) : (<SalonDetails direction={langDirection}>{t("host.none")}</SalonDetails>)}
                      </div>

                    );
                  })}
            </div>
            {!host &&
              <div className="submit-container">
                <SalonDetails direction={langDirection} fontSize={'18px'}>{t("host.guestsWillArrive")}</SalonDetails>
                <div className="salon-registration-container">
                  <div className="number-input">
                    <button
                      onClick={() => {
                        setInput(inputVal - 1);
                      }}
                      disabled={inputVal < 2 || calculateAvailableSpots < 2}
                      className={`minus ${inputVal < 2 && 'disabled'}`}
                    ></button>
                    <input
                      className="quantity"
                      min={1}
                      max={
                        calculateAvailableSpots >= 3 ? 3 : calculateAvailableSpots || 0
                      }
                      value={(inputVal < 1) ? 1 : (inputVal > 3) ? 3 : calculateAvailableSpots < inputVal ? calculateAvailableSpots : inputVal}
                      name="quantity"
                      type="number"
                      onChange={(e: any) => { setInput(e.target.value) }}
                    />
                    <button
                      onClick={(e: any) => {
                        setInput(inputVal + 1);
                      }}
                      className={`plus ${inputVal > 2 || inputVal === calculateAvailableSpots && 'disabled'}`}
                      disabled={inputVal > 2 || inputVal === calculateAvailableSpots}
                    ></button>
                  </div>
                  <div>
                    <SelectedSalonContainer guestsNumber={inputVal} salonDetails={selectedSalonDetails} distance={distance} setOpenSuccessPopup={setOpenSuccessPopup} setIsOpen={setIsOpen} isNoAvailableSpaces={isNoAvailableSpaces} />
                  </div>
                </div>
                {<p className="max-guests">{inputVal > 2 && t("host.maxGuests")}</p>}
              </div>}
          </Modal>
        )}
        <RegisterPopup popupIsOpen={openSuccessPopup} setPopupIsOpen={setOpenSuccessPopup} hostName={selectedSalonDetails.salon_name || selectedSalonDetails.host_name} salon={selectedSalonDetails} id={guestId} header={t("host.successRegister")} isNewSalon={false} icon={successIcon} />
        <div>
        </div>
      </>
    );
  }
);
export default SalonSearchLeftDrawerBar;
