import styled, {css} from 'styled-components/macro';
import { bodyFont } from '../../../utils/css/fonts';
import { body14 } from '../../../utils/css/typography';
import {
  colorRed,
  colorWhite,
  colorPink,
  listItemHover,
} from '../../../utils/css/colors';
import PhoneInput from 'react-phone-input-2';
import { input } from '../../../utils/css/input';
import { ScrollBarStyles } from '../../../utils/css/scrollBar';
import FormFieldContainer from '../FormFieldContainer';
import { mobile } from '../../../utils/css/media';


interface IInputProps {
  comment?: boolean;
  error?: any;
  height?: number;
  isPaddingLeft?: boolean;
  textAlign?: string;
  type?: string;
  width?: number | string;
  fontSize?: string;
  borderRadius?: string;
  borderWidth?: string;
  margin?: string;
  marginInlineStart?: string;
}

export const NewSalonInput = styled.input.attrs(() => ({ tabIndex: 1 }))<
  IInputProps
>`
  background-color: transparent !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid black !important;
  width: ${({ width }) => `${width ? width : '164px'} !important`};
  outline: none !important;
  margin-inline-start: ${({ marginInlineStart }) =>
    `${marginInlineStart ? marginInlineStart : '0px'} !important`};
`;

export const Input = styled.input.attrs(() => ({ tabIndex: 1 }))<IInputProps>`
  ${input};
  outline: none;
  height: ${({ height }) => (height ? height : 2.8)}rem;
  text-align: ${({ type }) => type === 'number' && 'left'};
  width: ${({ width }) => (width ? width : '420px')};
  padding-inline-end: ${({ isPaddingLeft }) => (isPaddingLeft ? '53px' : '')};
  position: relative;
  margin: ${({ margin }) => (margin ? margin : '5px')};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  border: 1px solid black;
  border-width: ${({ borderWidth }) =>
    borderWidth ? borderWidth : '0 0 1px 0'};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : 'none'};
  &:focus {
    box-shadow: ${({ error }) =>
      error
        ? '0 0px 0px rgba(107, 0, 0, 0.87) inset, 0 0 8px rgba(107, 0, 0, 0.87)'
        : 'none, 0 0 8px rgba(107, 85, 0, 0.87)'};
    border-bottom: 1px solid ${({ error }) => (error ? colorRed : 'black')};
    
  }

  ​&::-webkit-input-placeholder {
    font-family: ${bodyFont};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  ${mobile(css`
    width: 100%;
  `)};
`;

export const Textarea = styled.textarea.attrs(() => ({ tabIndex: 1 }))<{
  minHeight?: string;
  widthOfInput?: string;
  border?: string;
  padding?: string;
}>`
  height: ${({ minHeight }) => (minHeight ? minHeight : '9rem')};
  vertical-align: top;
  resize: none;
  boxShadow: none;
  outline: none !important;
  border: ${({ border }) => (border ? border : '')};
  padding: ${({ padding }) => (padding ? padding : '')};
  width: ${({ widthOfInput }) => (widthOfInput ? widthOfInput : '')};

  ::placeholder {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.54;
    font-family: ${bodyFont};
    text-align: start;
  }

`;

export const CustomInputPhone = styled(PhoneInput)<{
  lng: string;
  height?: string;
  type: 'material' | 'regular';
  isMobile?: boolean;
  fontSize?:string;
}>`
  direction: ${({ lng }) => (lng === 'he' ? 'rtl' : 'ltr')};
  height: ${({ height }) => (height ? height : '2.8rem')};
  width: 100%;
  input {
    ${input};
    width: ${({ isMobile }) => isMobile  ? '100% !important' : 'unset'};;
    padding: 4px;
    min-width: 11rem;
    height: 28px;
    max-width: ${({ isMobile }) => isMobile  ? 'unset !important' : '230px !important'};
    text-align: ${({ lng }) => (lng === 'he' ? 'right' : 'left')};
    font-size: ${({ fontSize }) => `${fontSize} !important` };;
    ${({ type }) =>
      type === 'material' &&
      'border-radius: 0px; border: none; border-bottom: 1px solid rgba(0, 0, 0, 0.42);'}
  }
  input :hover {
    ${({ type }) =>
      type === 'material' &&
      'border-bottom: 2px solid #000; transition: border-bottom .3s ease; '}
  }
`;

export const StyledDatePicker = styled.div.attrs({
  tabIndex: 0,
})`
  box-shadow: none !importent;
  border-bottom: 1px solid black;
  height: 28px;
  display: flex;
  justify-content: space-between;
  min-width: 140px;
  cursor: pointer;
  align-items: center;
  position: absolute;
  top: -20px;

  font-family: ${bodyFont};
  ${body14}
  &:focus {
    border-bottom: 1px solid rgba(107, 85, 0, 0.87);
  }

  ${mobile(css`
      position: relative;
  `)};

`;

export const TimePickerDiv = styled.div`
  height: 1.5rem;
`;

export const InputList = styled.div`
  width: 100%;
  border-radius: 6px;
  background-color: white;
  z-index: 1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 28px;
  max-height: 158px;
  overflow: auto;
  ${ScrollBarStyles}
`;

export const ListItem = styled.div<{ selected: boolean }>`
  background: ${({ selected }) => selected && colorPink};
  border-radius: 6px;
  color: ${({ selected }) => selected && colorWhite};
  height: max-content;
  min-height: 24px;
  padding: 2px 6px;
  transition: all 0.1s ease-in;
  margin: 6px 4px;
  display: flex;
  justify-content: space-between;

  &:hover {
    background: ${({ selected }) => !selected && listItemHover};
    cursor: pointer;
  }
`;

export const ErrorContainer = styled.div`
  position: absolute;
  top: 2.8rem;
`;

export const FlexRow = styled.div<{ direction?: 'rtl' | 'ltr' }>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction && direction === 'ltr' ? 'row-reverse' : 'row'};
`;

export const PhoneInputContainer = styled(FormFieldContainer)<{
  justifyContent?: string;
  alignItems?: string;
}>`
width: 100%;
  .flex-div {
    margin-bottom: 10px;
    justify-content: ${({ justifyContent }) =>
      justifyContent ? justifyContent : 'space-between'};
    align-items: ${({ alignItems }) => (alignItems ? alignItems : 'unset')};
  }
`;

export const DateInputDiv = styled.div`
  .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
  }
  
  .react-datepicker__current-month {
    color: #121212;
    display: flex;
    justify-content: flex-end;
    margin-inline-end: 30px;
    font-size: 1em;
  }
  .react-datepicker__header__dropdown {
    display: flex;
    margin-right: 30px;
    top: 7px;
    position: absolute;
    font-size: 1em;
    ${mobile(css`
      font-size: 0.7em;
  `)};
  }
  .react-datepicker__day-name {
    color: #121212;
    margin-top: 20px;
  }
  .react-datepicker__day--disabled {
    font-weight: 400;
  }
  .react-datepicker__day {
    font-weight: 700;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: #db838e;
    color: #fff;
  }

  .react-datepicker__day--selected {
    background-color: #db838e;
  }

  .react-datepicker__day--selected:hover {
    background-color: #db838e;
  }

  /* Ensure disabled selected days don't get any style */
  .react-datepicker__day--disabled.react-datepicker__day--selected,
  .react-datepicker__day--disabled.react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
    color: #ccc !important;
    pointer-events: none;
  }
    
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker {
    border: none;
    box-shadow: 0px 4px 13px rgb(182 182 182 / 50%);
    border-radius: 5px;
  }
  .react-datepicker__navigation {
    top: 1.15em;
    line-height: 2em;
    border: 0.6em solid transparent;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 0.5em;
    ${mobile(css`
      left: 0.5em;
  `)};
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 0.5em;
    ${mobile(css`
      right: 0.5em;
  `)};
  }
`;
export const HourInputDiv = styled.div`
  width: 200px;
  .react-datepicker__tab-loop .react-datepicker-popper {
    margin-inline-end: 10px;
    right: -1px;
  }
  .react-datepicker__tab-loop
    .react-datepicker-popper
    > div
    .react-datepicker--time-only {
    min-width: 140px;
    box-shadow: 0px 4px 13px rgb(182 182 182 / 50%);
    border: none;
  }
  .react-datepicker__tab-loop
    .react-datepicker-popper
    > div
    .react-datepicker--time-only
    .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__tab-loop
    .react-datepicker-popper
    > div
    .react-datepicker--time-only
    .react-datepicker__time-container {
    min-width: 140px;
  }
  .react-datepicker__header--time {
    background-color: #fff;
    border-bottom: none;
  }
  .react-datepicker-time__header {
    color: #121212;
    font-weight: 600;
    font-size: 14px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #db838e;
    color: #fff;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 5px;
    width: 65px;
    height: 32px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #db838e;
    border-radius: 3px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: #db838e;
  }

  ${mobile(css`
      width: 100%;
  `)};
`;