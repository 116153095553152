import * as yup from "yup";
import {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { ISalon } from "../../interfaces/hosts";

export const isURLValid = (url: string): boolean => {
  const urlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return urlRegex.test(url);
};

export const formSchema = yup.object().shape<any>({
  lat: yup.number(),
  lng: yup.number(),
  is_digital: yup.bool(),
  google_city_id: yup.string(),
  staff_free_text: yup.string(),
  comments_for_guests: yup.string(),
  org_branch: yup.string(),
  is_open_for_guests: yup.bool(),
  hosted_before: yup
    .bool()
    .required("host.hostProfile.validation.hosted_before"),
  evening_public: yup.bool(),
  apartment_number: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value)),
  floor: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value)),
  address: yup.string(),
  date: yup
    .date()
    .nullable()
    .required("host.hostProfile.validation.eventTimeRequired"),
  event_time: yup
    .string()
    .typeError("host.hostProfile.validation.eventTimeRequired")
    .required("host.hostProfile.validation.eventTimeRequired"),
  is_org: yup.bool(),
  contact_name: yup
    .string()
    .test(
      "match_name",
      "host.hostProfile.validation.contactNameRequired",
      function(name) {
        if (this.parent.is_org) {
          return name !== "";
        } else {
          return true;
        }
      }
    ),
  contact_phone: yup
    .string()
    .nullable()
    .test("match", "host.hostProfile.validation.contactPhoneNotValid", function(
      phone
    ) {
      if (this.parent.is_org) {
        if (!phone) return false;
        if (phone && phone.length < 8) return false;
        if (!isValidPhoneNumber(phone)) return false;
        if (!isPossiblePhoneNumber(phone)) return false;
        return true;
      } else {
        return true;
      }
    }),
  language: yup
    .number()
    .required("host.hostProfile.validation.languageRequired"),
  city_name: yup.string(),
  accessibility: yup.array().of(yup.string()),
  survivor_needed: yup
    .bool()
    .required("host.hostProfile.validation.survivorNeededRequired"),
  max_guests: yup
    .number()
    .positive("admin.addWitness.validation.mustBePositive")
    .typeError("admin.addWitness.validation.maxGuestsRequired")
    .lessThan(1000, "admin.addWitness.validation.maxGuestsMaxValue")
    .test(
      "match_guests",
      "admin.addWitness.validation.maxGuestsRequired",
      function(maxGuests) {
        return true;
      }
    ),
  site_link: yup
    .string()
    .typeError("formFields.siteLinkValidationError")
    .test("match", "formFields.siteLinkValidationError", function(site_link) {
      if (site_link && site_link !== "" && site_link !== null) {
        return isURLValid(site_link);
      } else {
        return true;
      }
    }),
  host_salon_training: yup
    .string()
    .nullable()
    .required("register.hostTrainingIsRequired"),
});


export const cleanDisplayAddress = (address: string = ""): string => {
  const trimmed = address.trim();

  if (!trimmed) return "";

  if (trimmed.includes(",")) {
    return trimmed.split(",").slice(0, -1).join(",").trim();
  }
  return trimmed.split(" ").slice(0, -1).join(" ");
};
